import React from "react";
import { useState, useEffect, useContext, createContext } from "react";
import _ from "lodash";
import { cdelay } from "../utils/utils";

const OverlayContext = createContext({});
export const useOverlayContext = () => useContext(OverlayContext);

export const OverlayWrapper = ({ children, ...props }) => {
  const [isopen, set_isopen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageStyle, setMessageStyle] = useState({});

  const padding_px = 10;
  const position_calc = {
    top: (rect) => ({
      top: `${rect.top - 40 - padding_px}px`,
      left: `${rect.left + rect.width / 2}px`,
      transform: "translateX(-50%)",
    }),
    "top-left": (rect) => ({
      top: `${rect.top - 40 - padding_px}px`,
      left: `${rect.left - padding_px}px`,
    }),
    "top-right": (rect) => ({
      top: `${rect.top - 40 - padding_px}px`,
      left: `${rect.left + rect.width + padding_px}px`,
      transform: "translateX(-100%)",
    }),
    bottom: (rect) => ({
      top: `${rect.bottom + 10 + padding_px}px`,
      left: `${rect.left + rect.width / 2}px`,
      transform: "translateX(-50%)",
    }),
    "bottom-left": (rect) => ({
      top: `${rect.bottom + 10 + padding_px}px`,
      left: `${rect.left - padding_px}px`,
    }),
    "bottom-right": (rect) => ({
      top: `${rect.bottom + 10 + padding_px}px`,
      left: `${rect.left + rect.width + padding_px}px`,
      transform: "translateX(-100%)",
    }),
    left: (rect) => ({
      top: `${rect.top + rect.height / 2}px`,
      left: `${rect.left - 150 - padding_px}px`,
      transform: "translateY(-50%)",
    }),
    "left-top": (rect) => ({
      top: `${rect.top - padding_px}px`,
      left: `${rect.left - 150 - padding_px}px`,
    }),
    "left-bottom": (rect) => ({
      top: `${rect.bottom + padding_px}px`,
      left: `${rect.left - 150 - padding_px}px`,
      transform: "translateY(-100%)",
    }),
    right: (rect) => ({
      top: `${rect.top + rect.height / 2}px`,
      left: `${rect.right + 10 + padding_px}px`,
      transform: "translateY(-50%)",
    }),
    "right-top": (rect) => ({
      top: `${rect.top - padding_px}px`,
      left: `${rect.right + 10 + padding_px}px`,
    }),
    "right-bottom": (rect) => ({
      top: `${rect.bottom + padding_px}px`,
      left: `${rect.right + 10 + padding_px}px`,
      transform: "translateY(-100%)",
    }),
  };

  const highlight_element = async (e, [pos, msg, extstyles = {}] = []) => {
    if (!e) return;

    const overlay = document.getElementById("tutorial-overlay");
    const cutout = document.getElementById("cutout");

    const rect = e.getBoundingClientRect();
    const x = rect.x - padding_px;
    const y = rect.y - padding_px;
    const width = rect.width + 2 * padding_px;
    const height = rect.height + 2 * padding_px;

    // Scroll into view if not visible
    e.scrollIntoView({ behavior: "smooth", block: "center" });

    // Wait for scrolling to complete
    await cdelay(0.5 * 1e3);

    // Show overlay and position cutout
    overlay.style.display = "block";
    cutout.setAttribute("x", x);
    cutout.setAttribute("y", y);
    cutout.setAttribute("width", width);
    cutout.setAttribute("height", height);

    // Update tutorial message and its position
    const calculatedStyle = position_calc[pos](rect);
    setMessage(msg);
    setMessageStyle({ ...calculatedStyle, ...extstyles });

    set_isopen(true);
  };

  const close_overlay = () => {
    const overlay = document.getElementById("tutorial-overlay");
    overlay.style.display = "none";
    set_isopen(false);
    setMessage("");
    setMessageStyle({});
  };

  const ocon = { highlight_element, close_overlay };

  return (
    <OverlayContext.Provider value={ocon}>
      <div id="tutorial-overlay">
        <svg width="100%" height="100%">
          <defs>
            <mask id="cutout-mask">
              <rect width="100%" height="100%" fill="white"></rect>
              <rect
                id="cutout"
                x="0"
                y="0"
                rx="6"
                ry="6"
                width="0"
                height="0"
                fill="black"
              ></rect>
            </mask>
          </defs>
          <rect
            width="100%"
            height="100%"
            fill="black"
            mask="url(#cutout-mask)"
            opacity="0.8"
          ></rect>
        </svg>
        <span
          id="tutorial-text"
          className="resp-text--1 bg-black/50 border border-acc0 backdrop-blur-md rounded-md resp-p-2 text-white pre-wrap"
          style={{
            position: "absolute",
            ...messageStyle,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            zIndex: 1001,
          }}
        >
          {message}
        </span>
        <div className="z-[1001] fr-sc fixed bottom-[1rem] p-2 w-full"></div>
      </div>
      {children}
    </OverlayContext.Provider>
  );
};
