import React, {
  useState,
  useMemo,
  useEffect,
  useContext,
  createContext,
} from "react";
import _ from "lodash";
import { PageWrapper } from "../components/ShortComps";
import { useAppContext } from "../App";
import { useAuthContext } from "../wrappers/AuthWrapper";
import { cdelay, getv, iso, jparse, jstr, nils } from "../utils/utils";
import {
  q_roadmap_base,
  q_roadmap_progress,
  q_roadmap_entry_tickets,
  qissuccesss,
  q_roadmap_check_progress,
  q_vaultinfo,
  q_roadmap_mark_progress,
} from "../queries/queries";
import { useQueries } from "react-query";
import { Img, Tag, tokenimgmap } from "../components/utilityComps";
import {
  faArrowLeft,
  faCheck,
  faChevronRight,
  faSpinner,
  faStar,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { twMerge } from "tailwind-merge";
import { Link } from "react-router-dom";
import { QuickSpliceCard } from "./QuickSplice";
import { PopUp, PopupCloseBtn } from "../components/popup";
import { vstarmap } from "../utils/vstar";
import { useAccountContext } from "../wrappers/AccountWrapper";

const RoadmapContext = createContext({});
const useRoadmapContext = () => useContext(RoadmapContext);

export const roadmap_status_redid = (v) => `roadmap-${v ? v.toLowerCase() : v}`;
export const get_roadmap_status = (v) => {
  let redid = roadmap_status_redid(v);
  let resp = jparse(localStorage.getItem(redid));
  return resp;
};

const mark_handlinglist = new Set([
  "signup",
  "claim-trainer",
  // "run-freerace",
  // "run-trialpaidrace1",
  "join-discord",
  "follow-twitter",
  // "run-questrace",
  "watch-tutorial-trails",
  // "run-freerace2",
  "watch-tutorial-claimers",
  "watch-tutorial-splicing",
  // "create-splice",
  "watch-tutorial-payinttxns",
  // "run-trialpaidrace-splice",
  "watch-tutorial-factions",
  // "run-questrace-splice",
  // 'win-paidraces100'      ,
  // 'win-paidraces250'      ,
  // 'win-paidraces500'      ,
  // 'win-paidraces1000'     ,
]);

const SetSection = ({ sidx }) => {
  const rcon = useRoadmapContext();
  const { vault, pdocs, vaultpdocs, active_sidx, active_tidx } = rcon;

  const set = useMemo(() => {
    let set = getv(vaultpdocs, `tasksset.${sidx}`, {});
    return set;
  }, [jstr(vaultpdocs), sidx]);

  return (
    <div
      class={twMerge(
        "my-1 resp-py-4 resp-px-2 w-full border-b border-acc0",
        sidx == active_sidx
          ? "bg-r2dark/60 border-acc0"
          : sidx > active_sidx
            ? "grayscale opacity-50 border-white/20"
            : "",
      )}
    >
      <div class="grid grid-cols-12 w-full">
        <div class="col-span-3">
          <div class="xs:w-[10rem] lg:w-[15rem] aspect-[1/1] mx-auto">
            <Img img={`https://cdn.dnaracing.run/imgs/${set.img}`} />
          </div>
        </div>
        <div class="col-span-9">
          <p class="resp-text-2 font-digi text-left">{set.title}</p>
          <div class="fc-ss resp-gap-1 my-2">
            {set.tasks.map((t, i) => {
              return <TaskRow key={i} {...{ tidx: i, sidx }} />;
            })}
          </div>

          <div class="my-1 flex flex-col justify-end items-end resp-gap-2">
            {(set.rewards || []).map((rewardid) => {
              let reward_doc = getv(set.reward_docs, rewardid);
              return (
                <RewardTag
                  {...{
                    key: rewardid,
                    rewardid,
                    reward_doc,
                    set_alldone: set.alldone,
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const EntryTicketTag = ({ rewardid, status, reward_doc }) => {
  const rcon = useRoadmapContext();
  const { vault } = rcon;
  const [qty] = useMemo(() => {
    let [a, c] = rewardid.split("-");
    return [c];
  }, [rewardid]);

  const [qotickets] = useQueries([
    q_roadmap_entry_tickets({ vault }, { enabled: status == "done" }),
  ]);

  const [remaining_n, tickets_n] = useMemo(() => {
    let tickets = getv(qotickets, "data.result.tickets", []);
    // console.log("tickets", tickets);
    let used = tickets.filter((t) => t.used == true).length;
    let rem = tickets.length - used;
    return [rem, tickets.length];
  }, [jstr(vault), jstr(qotickets.dataUpdatedAt)]);

  return (
    <>
      <div class="fc-cc resp-text-0 font-digi">
        <span>{`Trial Entry Tickets x${qty}`}</span>
        {qissuccesss(qotickets) && (
          <span>
            remaining: {remaining_n}/{tickets_n}
          </span>
        )}
        {status == "error" ? (
          <span class="text-red-400">{getv(reward_doc, "ext.err_msg")}</span>
        ) : null}
      </div>
    </>
  );
};

const RewardTag = ({ rewardid, reward_doc = null, set_alldone = false }) => {
  const rcon = useRoadmapContext();

  const status = useMemo(() => {
    if (set_alldone !== true) return "na";
    if (!_.isEmpty(reward_doc) && reward_doc.done === true) return "done";
    if (!_.isEmpty(reward_doc) && !nils(reward_doc.err)) return "error";
    else return "in_progress";
  }, [jstr(reward_doc), set_alldone]);

  const reward_name_jsx = useMemo(() => {
    if (rewardid.startsWith("vstar")) {
      let [a, vstar] = rewardid.split("-");
      let vinf = vstarmap[vstar];
      return `Vault Star:${_.capitalize(vinf[1])}`;
    } else if (rewardid.startsWith("trial_entrytickets")) {
      // return React.createElement(EntryTicketTag, { rewardid, status });
      return "comp";
    } else {
      return rewardid;
    }
  }, [rewardid]);

  return (
    <>
      <Tag
        className={twMerge(
          "bg-r2dark/60 fr-sc resp-gap-1 text-white border border-acc4 text-acc4",
          status == "na"
            ? "border-acc4"
            : status == "in_progress"
              ? "border-yellow-500"
              : status == "error"
                ? "border-red-500"
                : status == "done"
                  ? "border-green-500"
                  : null,
        )}
      >
        <div class="w-[1.5rem] fr-cc px-1">
          {status == "na" ? null : status == "in_progress" ? (
            <FontAwesomeIcon
              icon={faSpinner}
              className="resp-text-2 text-yellow-500"
            />
          ) : status == "done" ? (
            <FontAwesomeIcon
              icon={faCheck}
              className="resp-text-2 text-green-500"
            />
          ) : status == "error" ? (
            <FontAwesomeIcon
              icon={faTimes}
              className="resp-text-2 text-red-500"
            />
          ) : null}
        </div>

        {nils(rewardid) ? (
          "--"
        ) : rewardid.startsWith("trial_entrytickets") ? (
          <EntryTicketTag {...{ rewardid, status, reward_doc }} />
        ) : (
          <span className="resp-text-0 font-digi">{reward_name_jsx}</span>
        )}
      </Tag>
    </>
  );
};

const TaskRow = ({ tidx, sidx }) => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const rcon = useRoadmapContext();
  const { vault, vaultpdocs, active_sidx, active_tidx } = rcon;
  const { qo_progs } = rcon;
  const isactive = active_sidx == sidx && active_tidx == tidx;
  const clickable = useMemo(() => {
    if (sidx < active_sidx) return true;
    else if (sidx == active_sidx && tidx <= active_tidx) return true;
    return false;
  }, [
    jstr({
      sidx,
      tidx,
      active_sidx,
      active_tidx,
    }),
  ]);

  const task = useMemo(() => {
    let task = getv(vaultpdocs, `tasksset.${sidx}.tasks.${tidx}`, {});
    // console.log("task.link", task.link);
    return task;
  }, [jstr(vaultpdocs), sidx, tidx]);

  const inner_jsx = (
    <>
      <div class="fr-sc resp-gap-2">
        <div class="w-[1.5rem] h-full relative">
          {task.done == true ? (
            <FontAwesomeIcon
              className="text-green-300 resp-text-2"
              icon={faCheck}
            />
          ) : isactive ? (
            <div className="shake-right-anim">
              <FontAwesomeIcon
                className="text-yellow-300 resp-text-0"
                icon={faChevronRight}
              />
            </div>
          ) : null}
        </div>
        <div
          class={twMerge(
            "rep-text-1 font-mon fr-sc resp-gap-2 flex-wrap",
            task.done == true ? "text-slate-400 line-through" : "text-white",
          )}
        >
          <p>{task.msg}</p>
          {isactive && !nils(getv(task, "ext.required")) && (
            <div className="fr-sc resp-gap-1 w-max">
              <span className="text-yellow-500">| Progress:</span>
              <span class="font-digi resp-text-0 text-yellow-500">
                {getv(task, "ext.progress")} / {getv(task, "ext.required")}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );

  const [is_popup, popup_key] = useMemo(() => {
    if (nils(task.link)) return [false, null];
    let is_popup = task.link.startsWith("popup=");
    let k = is_popup ? task.link.split("popup=")[1] : null;
    return [is_popup, k];
  }, [task.link]);

  const CompList = {
    QuickSpliceCard: QuickSpliceCard,
  };
  const [pop, set_pop] = useState(
    isactive && psearch.autopop == "open" ? true : false,
  );

  const ext_click_fn = () => {
    if (task.done) return;
    let taskid = getv(task, "id");
    if (mark_handlinglist.has(taskid)) {
      rcon.mark_progress({ taskid });
    }
  };

  return is_popup && CompList[popup_key] ? (
    <>
      <div
        onClick={() => {
          if (!clickable) return;
          set_pop(true);
          ext_click_fn();
        }}
        className={twMerge(clickable ? "cursor-pointer" : "")}
      >
        {inner_jsx}
        <PopUp
          {...{
            openstate: pop,
            onOpen: () => set_pop(true),
            onClose: () => set_pop(false),
            overlayclose: false,
            wrapcn: "top-[6rem]",
            innercn: "translate-y-[0%]",
          }}
        >
          <div class="relative">
            <PopupCloseBtn closepopup={() => set_pop(false)} />
            {React.createElement(CompList[popup_key], {
              vault,
              post_mint_fn: async (ext) => {
                console.log("task", task);
                if (task.done == true) return;
                console.log("post_mint_fn", ext);
                await q_roadmap_check_progress({
                  vault,
                  taskid: task.id,
                  ext,
                }).queryFn();
                await cdelay(2000);
                await qo_progs.refetch();
              },
            })}
          </div>
        </PopUp>
      </div>
    </>
  ) : (
    <>
      <div
        onClick={(e) => {
          ext_click_fn();
        }}
        className={twMerge(clickable ? "cursor-pointer" : "")}
      >
        {!clickable || nils(task.link) ? (
          <> {inner_jsx} </>
        ) : task.link.startsWith("https") ? (
          <a href={task.link} target="_blank">
            {inner_jsx}
          </a>
        ) : (
          <Link to={task.link}>{inner_jsx}</Link>
        )}
      </div>
    </>
  );
};

export const Roadmap = ({ vault }) => {
  const aucon = useAuthContext();

  const isauvault = useMemo(() => {
    return aucon.vault === vault;
  }, [aucon?.vault, vault]);

  const [qo_vdoc, qo_base, qo_progs] = useQueries([
    q_vaultinfo({ vault }, { enabled: !nils(vault) }),
    q_roadmap_base({ vault }),
    q_roadmap_progress(
      { vault },
      {
        staleTime: 15 * 1e3,
        refetchInterval: 15 * 1e3,
      },
    ),
  ]);
  const vdoc = useMemo(() => {
    let vdoc = getv(qo_vdoc, "data.result", {});
    // console.log("vdoc", vdoc);
    return vdoc;
  }, [jstr(qo_vdoc.data), qo_vdoc.dataUpdatedAt]);

  const basedoc = useMemo(() => {
    let basedoc = getv(qo_base, "data.result", {});
    // console.log("basedoc", basedoc);
    return basedoc;
  }, [jstr(qo_base.data), qo_base.dataUpdatedAt]);
  const [pdocs_fullres, pdocs] = useMemo(() => {
    let d = getv(qo_progs, "data.result", {});
    let pdocs = getv(qo_progs, "data.result.pdocs", []);
    pdocs = _.keyBy(pdocs, "taskid");
    return [d, pdocs];
  }, [jstr(qo_progs), qo_progs.dataUpdatedAt]);

  const [rewards_at_map, rewards_at] = useMemo(() => {
    const rewards_at = _.chain(basedoc.tasksset)
      .map((set, sidx) => {
        let lasttask = _.last(set.tasks);
        return { last_taskid: lasttask.id, rewards: set.rewards, sidx };
      })
      .value();
    const rewards_at_map = _.keyBy(rewards_at, "last_taskid");
    return [rewards_at_map, rewards_at];
  }, [jstr(basedoc)]);

  const [vaultpdocs, active_sidx, active_tidx] = useMemo(() => {
    if (nils(pdocs) || nils(basedoc)) return [{}, 0, 0];
    let init = getv(vdoc, "init_setlv", 1);

    let si = 0;
    let ti = 0;
    let d = _.cloneDeep(basedoc);

    d.tasksset = _.chain(d.tasksset)
      .map((set, sidx) => {
        let vs = _.chain(set.tasks)
          .map((t, tidx) => {
            let v = _.cloneDeep(getv(pdocs, t.id) || {});
            if (sidx < init) v.done = true;
            if (v.done) ti++;
            return { ...t, ...v };
          })
          .value();
        let alldone = vs.every((e) => e.done == true);
        if (alldone) {
          si = Math.max(si + 1, sidx);
          ti = 0;
        } else {
        }

        let s = { ...set, tasks: vs };

        s.alldone = alldone;
        if (alldone) {
          let lastt = _.chain(s.tasks)
            .find((t) => !_.isEmpty(t.post_ext))
            .value();
          s.rewards = getv(lastt, "post_ext.rewards_ids", []);
          s.reward_docs = _.chain(s.rewards)
            .map((rewardid) => getv(pdocs, rewardid))
            .compact()
            .keyBy("taskid")
            .value();
          // console.log("s.reward_docs", s.reward_docs);
        }
        return s;
      })
      .value();
    d.vault = vault;
    d.id = `${d.id}-${vault}`;
    // console.log("basedoc.tasksset", basedoc.tasksset);
    // console.log("vaultpdocs.tasksset", d.tasksset, basedoc.tasksset);
    // console.log({ si, ti });
    return [d, si, ti];
  }, [jstr(vdoc), jstr(pdocs), jstr(basedoc)]);

  const save = { vault, active_sidx, active_tidx };
  let currtaskid = useMemo(() => {
    if (nils(vaultpdocs)) return null;
    let currtask = getv(
      vaultpdocs,
      `tasksset.${active_sidx}.tasks.${active_tidx}`,
    );
    let ob = { ...save, currtask, updated_at: iso() };
    let redid = roadmap_status_redid(vault);
    localStorage.setItem(redid, JSON.stringify(ob));
    return currtask?.id;
  }, [jstr(save), jstr(vaultpdocs)]);

  useEffect(() => {
    console.log("currtaskid", currtaskid);
    if (currtaskid == "claim-trainer") {
    }
  }, [jstr(currtaskid)]);

  const mark_progress = async ({ taskid, ext = {} }) => {
    try {
      if (!isauvault) return;
      let resp = await q_roadmap_mark_progress({
        vault,
        taskid,
        ext,
      }).queryFn();
      console.log("mark_progress resp", resp);
      if (resp.status !== "success") return;
      await cdelay(2 * 1e3);
      await qo_progs.refetch();
    } catch (err) {}
  };

  const rcon = {
    vault,
    vdoc,

    vaultpdocs,
    active_sidx,
    active_tidx,
    rewards_at_map,
    pdocs,

    qo_vdoc,
    qo_progs,
    qo_base,

    currtaskid,
    mark_progress,
  };
  return (
    <RoadmapContext.Provider value={rcon}>
      <div class="">
        <div id="roadmap_info" class="fc-cc resp-gap-1 font-digi text-acc0">
          <p class="resp-text-2 text-white">Roadmap</p>
          {!_.isEmpty(vdoc) && (
            <>
              <p class="resp-text-2">{vdoc.vault_name}</p>
              <p class="resp-text--1">{vault}</p>
              <div class="fr-cc resp-gap-1">
                {[...new Array(vdoc.vstar)].map((e, i) => {
                  let v = vstarmap[i + 1];
                  // let v = vstarmap[vdoc.vstar];
                  let cn = v[3];
                  return (
                    <FontAwesomeIcon
                      icon={faStar}
                      className={twMerge("resp-text-2", cn)}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
        <div class="h-[2rem]"></div>
        {!_.isEmpty(vaultpdocs?.tasksset) && (
          <>
            {vaultpdocs.tasksset.map((set, i) => {
              return <SetSection key={i} {...{ sidx: i }} />;
            })}
          </>
        )}
      </div>
    </RoadmapContext.Provider>
  );
};

export const RoadmapPage = () => {
  const appcon = useAppContext();
  const aucon = useAuthContext();
  const { psearch, upd_psearch } = appcon;

  const vault = useMemo(() => {
    let v = nils(psearch.v) ? null : psearch.v.toLowerCase();
    if (!nils(v)) return v;
    return aucon.vault;
  }, [psearch.v, aucon.vault]);

  return (
    <PageWrapper
      page_title_fn={() => "Roadmap"}
      cont_cn={"lg:w-[80rem] xs:w-full max-w-[95vw]"}
    >
      {!nils(vault) && <Roadmap {...{ vault }} />}
    </PageWrapper>
  );
};

const block_paths = new Set([
  "/trainer-leaderboard",
  // "/races"
]);

export const roadmap_show_link = ({ vstar, path, psearch }) => {
  if (block_paths.has(path)) return false;
  return vstar > 0 && vstar < 4;
};

export const def_roadmap_link = "/trainer-leaderboard?tab=roadmap";
export const RoadmapBackLink = ({ override_show = null }) => {
  const appcon = useAppContext();
  const { path, psearch, upd_psearch } = appcon;

  const aucon = useAuthContext();
  const { vault } = aucon;

  const acccon = useAccountContext();
  const { vdoc } = acccon;

  const [vstar, show] = useMemo(() => {
    let vstar = getv(vdoc, "vstar", 0);
    let show = !nils(override_show)
      ? override_show
      : roadmap_show_link({ vstar, path, psearch });
    return [vstar, show];
  }, [jstr(vdoc), override_show, path, jstr(psearch)]);

  const link = def_roadmap_link;

  if (show == false) return null;
  return (
    <div class="fr-sc my-2 w-full">
      <Link to={link}>
        <Tag className="text-acc4 -skew-x-12 border border-acc4 bg-r2lig/20 backdrop-blur-md">
          <div class="fr-sc resp-gap-2 resp-text-0">
            <FontAwesomeIcon icon={faArrowLeft} />
            <span class="">Back to Roadmap</span>
          </div>
        </Tag>
      </Link>
    </div>
  );
};
