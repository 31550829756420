export const vstarmap = {
  1: ["white", "beginner", "starting point", "text-white"],
  2: ["yellow", "novice", "basic understanding", "text-yellow-400"],
  3: ["orange", "intermediate", "developing skills", "text-orange-400"],
  4: ["red", "skilled", "proficient in core areas", "text-red-400"],
  5: ["purple", "advanced", "mastery of core skills", "text-purple-400"],
  6: ["blue", "expert", "in-depth knowledge and experience", "text-blue-400"],
  7: [
    "green",
    "master",
    "exceptional ability and innovation",
    "text-green-400",
  ],
  8: [
    "gold",
    "elite",
    "top-tier performance and recognition",
    "text-yellow-400 shadow shadow-md shadow-yellow-500",
  ],
};
