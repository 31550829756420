import React from "react";
import ReactDOM from "react-dom/client";
import "./styles.scss";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  FingerprintJSPro,
  FpjsProvider,
} from "@fingerprintjs/fingerprintjs-pro-react";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    {/* <React.StrictMode> */}
    <QueryClientProvider client={queryClient}>
      <FpjsProvider
        loadOptions={{
          apiKey: "rTBzjKc50MRa47c3NCP1",
          endpoint: [
            "https://fingerprint.dnaracing.run",
            FingerprintJSPro.defaultEndpoint,
          ],
          scriptUrlPattern: [
            "https://fingerprint.dnaracing.run/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
            FingerprintJSPro.defaultScriptUrlPattern,
          ],
        }}
      >
        <Router>
          <App />
        </Router>
      </FpjsProvider>
    </QueryClientProvider>
    {/* </React.StrictMode> */}
  </>,
);
